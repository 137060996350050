import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../styles/bootstrap.css";
import "../styles/App.css";
import Home from "./home/Home";
import About from "./about/About";
import Projects from "./projects/Projects"
import NavigationBar from "../components/global/NavigationBar"
import NoMatch from "./global/404";

class App extends Component {
    render(){
        return(
            <Router>
                <NavigationBar />
                    <div className="container">
                        <Switch>
                            <Route exact path="/" component={ Home } />
                            <Route exact path="/about" component={ About } />
                            <Route exact path="/projects" component={ Projects } />
                            <Route component={ NoMatch } />
                        </Switch>
                    </div>
            </Router>
        )
    }
}

export default App;