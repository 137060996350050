import React, { Component } from "react";

class Projects extends Component {
    render() {
        return(
            <React.Fragment>
                <br />
                <h1>Projects</h1>
            </React.Fragment>
        )
    }
}

export default Projects;