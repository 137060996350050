import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavigationBar extends Component {
    render(){
        return(
            <React.Fragment>
                <nav className="navbar sticky-top navbar-dark navbar-expand-lg bg-dark">
                <a href="/" className="navbar-brand">Tom Towler</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul class="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/projects" className="nav-link">Projects</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </React.Fragment>
        )
    }
}

export default NavigationBar;