import React, { Component } from "react";

class Home extends Component {
    render() {
        return(
            <React.Fragment>
                <br />
                <h1>Welcome</h1>
            </React.Fragment>
        )
    }
}

export default Home;