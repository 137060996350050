import React, { Component } from "react";

class About extends Component {
    render() {
        return(
            <React.Fragment>
                <br />
                <h1>About</h1>
            </React.Fragment>
        )
    }
}

export default About;